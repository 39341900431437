import React from "react";
import TableMobileComponent from "./tableMobile";
import TableDesktopComponent from "./tableDesktop";

export default function Table({ tableJSON }) {
    const [isMobile, setIsMobile] = React.useState(window.innerWidth <= 768);

    // Update isMobile state when window is resized
    React.useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);


    return (
        <div className="container mx-auto px-4 sm:px-8">
            <div className="py-8">
                <div>
                    <h2 className="text-2xl font-semibold leading-tight">{tableJSON.tableName}</h2>
                </div>
                <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
                    <div className="inline-block shadow-md rounded-lg overflow-hidden"></div>
                    {isMobile ? <TableMobileComponent tableJSON={tableJSON} /> : <TableDesktopComponent tableJSON={tableJSON} />}
                </div>
            </div>
        </div>
    );
}
