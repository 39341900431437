/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { appSettingActions } from '../../_actions';
import { useDispatch, useSelector } from 'react-redux';
import CreateAppSetting from './model/CreateAppSetting';
import EditAppSetting from './model/EditAppSettings';

function Index() {

    const selector = useSelector(state => state)
    const [appSettingData, setAppSettingData] = useState({
        list: [],
        total: 0
    })
    const dispatch = useDispatch()
    useEffect(() => {
        const data = {
            "keyWord": "",
            "fromDate": "",
            "toDate": "",
            "sortOrder": "desc",
            "sortBy": "createdAt",
            "pageNo": 1,
            "size": 10
        }
        dispatch(appSettingActions.get(data))
    }, []);

    useEffect(() => {
        setAppSettingData(selector?.appSetting?.getAppSettingSuccess)
    }, [selector])


    const handleCheckboxChange = (data) => {
        // setIsChecked(!isChecked);
        dispatch(appSettingActions.updateStatus(data, {
            "keyWord": "",
            "fromDate": "",
            "toDate": "",
            "sortOrder": "desc",
            "sortBy": "createdAt",
            "pageNo": 1,
            "size": 10
        }))
    };

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalOpen2, setIsModalOpen2] = useState(false);


    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    const [formData, setFormData] = useState({
        name: '',
        key: '',
        value: ''
    });

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData({ ...formData, [id]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(appSettingActions.create(formData, {
            "keyWord": "",
            "fromDate": "",
            "toDate": "",
            "sortOrder": "desc",
            "sortBy": "createdAt",
            "pageNo": 1,
            "size": 10
        }))
        setFormData({
            name: '',
            key: '',
            value: ''
        })
        toggleModal();
    };


    const toggleModal2 = () => {
        setIsModalOpen2(!isModalOpen2);
    };

    const [formData2, setFormData2] = useState({
        id: '',
        name: '',
        key: '',
        value: ''
    });

    const handleChange2 = (e) => {
        const { id, value } = e.target;
        setFormData2({ ...formData2, [id]: value });
    };

    const handleSubmit2 = (e) => {
        e.preventDefault();
        dispatch(appSettingActions.update({id:formData2.id, value:formData2.value}, {
            "keyWord": "",
            "fromDate": "",
            "toDate": "",
            "sortOrder": "desc",
            "sortBy": "createdAt",
            "pageNo": 1,
            "size": 10
        }))
        setFormData2({
            id: '',
            name: '',
            key: '',
            value: ''
        })
        toggleModal2();
    };

    return (
        <div>
            <div className='p-6 flex justify-between'>
                <h1 className='text-2xl font-bold'>
                    App Setting
                </h1>

                <button
                    onClick={toggleModal}
                    className="block text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    type="button"
                >
                    Create
                </button>
            </div>

            <div className="relative overflow-x-auto shadow-md sm:rounded-lg p-10 rounded-sm">
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 rounded-md">
                    <thead className="text-xs text-white bg-gray-600">
                        <tr>
                            <th scope="col" className="px-6 py-3">
                                Name
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Key
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Value
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Status
                            </th>
                            <th scope="col" className="px-6 py-3">
                                <span className="sr-only">Edit</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {appSettingData && Array.isArray(appSettingData.list) && appSettingData.list.length > 0 ? appSettingData.list.map((ele, id) =>
                        (<tr className="bg-gray-400 border-b text-white capitalize hover:bg-gray-500" key={id}>
                            <th
                                scope="row"
                                className="px-6 py-4 font-medium text-white whitespace-nowrap"
                            >
                                {ele.name}
                            </th>
                            <td className="px-6 py-4">{ele.key}</td>
                            <td className="px-6 py-4">{ele.value}</td>
                            <td className="px-6 py-4">
                                <label className="inline-flex items-center cursor-pointer">
                                    <input
                                        type="checkbox"
                                        defaultValue=""
                                        className="sr-only peer"
                                        checked={ele.isDisable}
                                        onChange={() => handleCheckboxChange({ id: ele._id })}
                                    />
                                    <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                                </label>
                            </td>
                            <td className="px-6 py-4 text-right">
                                <div className="font-medium text-blue-600 " onClick={() => {
                                    setIsModalOpen2(!isModalOpen2)
                                    setFormData2(() => ({ ...ele, id: ele._id }))
                                }}>
                                    Edit
                                </div>
                            </td>
                        </tr>)) : <tr>Data Not Available</tr>}
                    </tbody>
                </table>
            </div>
            <CreateAppSetting isModalOpen={isModalOpen} toggleModal={toggleModal} handleSubmit={(e) => handleSubmit(e)} handleChange={(e) => handleChange(e)} formData={formData} />
            <EditAppSetting isModalOpen={isModalOpen2} toggleModal={toggleModal2} handleSubmit={(e) => handleSubmit2(e)} handleChange={(e) => handleChange2(e)} formData={formData2} setFormData={setFormData2} />

        </div>
    );
}

export default Index;
