
export function authHeader() {
    let user = JSON.parse(window.sessionStorage.getItem('adminuser'));
    if (user && user.token) {
        return { 'Authorization': 'Bearer ' + user.token };
    } else {
        return {};
    }
}

export function logoutFunction() {
    window.sessionStorage.removeItem('adminuser');
    window.location.replace('/login');
    window.location.reload()
}

export const headerForPublicAPI = new Headers({
    'Content-Type': 'application/json',
})


export const headerForPrivateAPI = new Headers({
    'Content-Type': 'application/json',
    'Authorization': authHeader().Authorization
})

export const headerForPrivateMediaAPI = new Headers({
    'Content-Type': 'multipart/form-data',
    'Authorization': authHeader().Authorization
})

export const APIcallFunction = async (credentials) => {
    const requestOptions = {
        method: credentials.method,
        headers: credentials.header,
        body: JSON.stringify(credentials.body)
    };

    try {
        const response = await fetch("https://www.smtpchats.com/api/v1" + credentials.endPoint, requestOptions);
        const responseData = await handleResponse(response);
        return {
            data : responseData.data
        };
    } catch (error) {
        console.error('Error:', error);
        throw error; 
    }
};


function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                logoutFunction();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        if (data.error) {
            if (data.code === 3) {
                logoutFunction();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}
