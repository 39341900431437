import { appSettingConstants } from '../_constants';
import { APIcallFunction, headerForPrivateAPI, logoutFunction } from '../_helpers';
import { alertActions } from './alert.actions';
export const appSettingActions = {
    logout,
    create,
    update,
    get,
    deleteApp,
    updateStatus
};


function create(data, getData) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/appSetting/createAppSetting'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                user => {
                    dispatch(success(user));
                    dispatch(alertActions.success("Create Successfully"))
                    dispatch(get(getData))
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(user) { return { type: appSettingConstants.CREATE_APP_SETTINGS_REQUEST, user } }
    function success(user) { return { type: appSettingConstants.CREATE_APP_SETTINGS_SUCCESS, user } }
    function failure(error) { return { type: appSettingConstants.CREATE_APP_SETTINGS_FAILURE, error } }
}
function get(data) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/appSetting/getAppSettingList'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                user => {
                    dispatch(success(user));

                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(user) { return { type: appSettingConstants.GET_APP_SETTINGS_REQUEST, user } }
    function success(user) { return { type: appSettingConstants.GET_APP_SETTINGS_SUCCESS, user } }
    function failure(error) { return { type: appSettingConstants.GET_APP_SETTINGS_FAILURE, error } }
}

function update(data, getData) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/appSetting/updateAppSetting'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                user => {
                    dispatch(success(user));
                    dispatch(alertActions.success("Update Successfully"))
                    dispatch(get(getData))
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(user) { return { type: appSettingConstants.UPDATE_APP_SETTINGS_REQUEST, user } }
    function success(user) { return { type: appSettingConstants.UPDATE_APP_SETTINGS_SUCCESS, user } }
    function failure(error) { return { type: appSettingConstants.UPDATE_APP_SETTINGS_FAILURE, error } }
}

function deleteApp(data, getData) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/appSetting/deleteAppSetting'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                user => {
                    dispatch(success(user));
                    dispatch(alertActions.success("Delete Successfully"))
                    dispatch(get(getData))

                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(user) { return { type: appSettingConstants.DELETE_APP_SETTINGS_REQUEST, user } }
    function success(user) { return { type: appSettingConstants.DELETE_APP_SETTINGS_SUCCESS, user } }
    function failure(error) { return { type: appSettingConstants.DELETE_APP_SETTINGS_FAILURE, error } }
}


function updateStatus(data, getData) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/appSetting/updateAppSettingStatus'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                user => {
                    dispatch(success(user));
                    dispatch(alertActions.success("Delete Successfully"))
                    dispatch(get(getData))

                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(user) { return { type: appSettingConstants.UPDATE_STATUS_APP_SETTINGS_REQUEST, user } }
    function success(user) { return { type: appSettingConstants.UPDATE_STATUS_APP_SETTINGS_SUCCESS, user } }
    function failure(error) { return { type: appSettingConstants.UPDATE_STATUS_APP_SETTINGS_FAILURE, error } }
}


function logout() {
    logoutFunction()
    return { type: appSettingConstants.LOGOUT };
}

