import React from 'react';
import DashboardJSON from './Dashboard.json'
import Table from '../../components/Atoms/Tables/index.js';


const Dashboard = () => {
  // const { dashboard, transaction, users, teachcourse } = useSelector(state => state);
  // const { loginToThisAccount } = useSelector(state => state.users);

  return (
    <>
      <div className="z-0 overflow-hidden overflow-y-auto focus:outline-none">
        <div className="relative">
          <div className="relative z-20 flex min-h-screen">
            <div className="flex w-full overflow-hidden ">
              <div className="flex flex-col flex-1 overflow-hidden">
                <main className="relative flex-1 ">
                  <div className="p-3 2xl:p-10 sm:p-5">
                    <div className="mx-auto max-w-screen-3xl">
                      <sectiion className="flex justify-between my-4 ">
                        <div>
                          <p className='text-2xl font-bold'>{DashboardJSON.Heading}</p>
                        </div>
                      </sectiion>
                      <Table tableJSON={DashboardJSON.tableJSON} />
                    </div>
                  </div>
                </main>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
