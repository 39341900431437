import { combineReducers } from 'redux';
import usersReducer from './users.reducer';
import alertReducer from './alert.reducer';
import appSetting from './appsetting.reducer';

const rootReducer = combineReducers({
  users: usersReducer,
  alert: alertReducer,
  appSetting: appSetting
});

export default rootReducer;
