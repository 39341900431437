export const appSettingConstants = {
    LOGOUT: 'APP_SETTINGS_LOGOUT',


    CREATE_APP_SETTINGS_REQUEST: 'CREATE_APP_SETTINGS_REQUEST',
    CREATE_APP_SETTINGS_SUCCESS: 'CREATE_APP_SETTINGS_SUCCESS',
    CREATE_APP_SETTINGS_FAILURE: 'CREATE_APP_SETTINGS_FAILURE',

    GET_APP_SETTINGS_REQUEST: 'GET_APP_SETTINGS_REQUEST',
    GET_APP_SETTINGS_SUCCESS: 'GET_APP_SETTINGS_SUCCESS',
    GET_APP_SETTINGS_FAILURE: 'GET_APP_SETTINGS_FAILURE',

    UPDATE_APP_SETTINGS_REQUEST: 'UPDATE_APP_SETTINGS_REQUEST',
    UPDATE_APP_SETTINGS_SUCCESS: 'UPDATE_APP_SETTINGS_SUCCESS',
    UPDATE_APP_SETTINGS_FAILURE: 'UPDATE_APP_SETTINGS_FAILURE',

    DELETE_APP_SETTINGS_REQUEST: 'DELETE_APP_SETTINGS_REQUEST',
    DELETE_APP_SETTINGS_SUCCESS: 'DELETE_APP_SETTINGS_SUCCESS',
    DELETE_APP_SETTINGS_FAILURE: 'DELETE_APP_SETTINGS_FAILURE',

    UPDATE_STATUS_APP_SETTINGS_REQUEST: "UPDATE_STATUS_APP_SETTINGS_REQUEST",
    UPDATE_STATUS_APP_SETTINGS_SUCCESS: "UPDATE_STATUS_APP_SETTINGS_SUCCESS",
    UPDATE_STATUS_APP_SETTINGS_FAILURE: "UPDATE_STATUS_APP_SETTINGS_FAILURE",
};
