import { userConstants } from '../_constants';
import { alertActions} from './alert.actions';
import { APIcallFunction, headerForPublicAPI, logoutFunction } from '../_helpers';
export const userActions = {
    login,
    logout,
};
function login(data, navigate) {
    const credentials = {
        header : headerForPublicAPI,
        method : "POST",
        body : data ?? {},
        endPoint : '/admin/adminLogin'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                user => {
                    window.sessionStorage.setItem("adminuser", JSON.stringify(user.data))
                    dispatch(success(user));
                    dispatch(alertActions.success("Login Successfully"))
                    if (navigate) {
                        navigate('/app/dashboard');
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}



function logout() {
    logoutFunction()
    return { type: userConstants.LOGOUT };
}

