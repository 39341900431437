import React, { useState } from "react";
import { useDispatch } from 'react-redux';
import { userActions } from '../../_actions';
import LoginJSON from './login.json'
import { useNavigate } from 'react-router';


const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [fieldslogin, setFieldsLogin] = useState({});
  const [errorslogin, setErrorsLogin] = useState({});

  const inputChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFieldsLogin(prevState => ({ ...prevState, [name]: value }));
    setErrorsLogin(prevState => ({ ...prevState, [name]: "" }));
  };

  const loginSubmit = (e) => {
    e.preventDefault();
    if (handleValidationLogin()) {
      const { userName, password } = fieldslogin;
      dispatch(userActions.login({ userName: userName, password: password }, navigate ));
    }
  };

  const handleValidationLogin = () => {
    let formIsValid = true;
    let errors = {};

    if (!fieldslogin.userName || fieldslogin.userName === "") {
      formIsValid = false;
      errors.userName = LoginJSON.ErrorMsg.userName;
    }

    if (!fieldslogin.password) {
      formIsValid = false;
      errors.password = LoginJSON.ErrorMsg.password;
    }

    setErrorsLogin(errors);
    return formIsValid;
  };

  return (
    <>
      <div className="overflow-hidden">
        <div className="bg-slate-600 relative">
          <div className="min-h-screen flex flex-col justify-center py-12 px-3 sm:px-6 lg:px-8 relative z-20">
            <h1 className="font-bold text-center text-[36px] mb-5 text-lime-300">{LoginJSON.messageWeLCome}</h1>
            <div className=" mx-auto w-full  max-w-lg">
              <div className="bg-gray-100 border border-gray-800 py-16 shadow rounded-xl px-6 sm:px-12">
                <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                  <h2 className="mb-12 text-center text-3xl leading-9 font-bold capitalize text-dark-400 tracking-widest">{LoginJSON.heading}</h2>
                </div>

                <form autoComplete="off">
                  <div className="{otpSent?'disableArea':''}">
                    <div className="mt-1  shadow-sm relative">
                      <input className={`px-12 py-5 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${!errorslogin["userName"] ? "border border-gray-400 placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                        id="userName" name="userName" placeholder="User name" type="text" onChange={inputChange} />
                      {errorslogin["userName"] ?
                        <div className="invalid-feedback text-slate-900">
                          {errorslogin["userName"]}
                        </div>
                        : null}
                      <span className="absolute top-4 left-5 text-gray-500"><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                      </svg></span>
                    </div>
                  </div>

                  <div className="mt-6">
                    <div className="mt-1 rounded-md shadow-sm relative">
                      <input className={`border-1 px-12 py-5 placeholder-blueGray-400 text-blueGray-600 bg-white text-black rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${!errorslogin["password"] ? " placeholder-gray-500" : "border-opacity-100 border border-red-500 "}`}
                        id="password" name="password" placeholder="Password" type="password" onChange={inputChange} />
                      {errorslogin["password"] ?
                        <div className="invalid-feedback text-slate-900">
                          {errorslogin["password"]}
                        </div>
                        : null}
                      <span className="absolute top-4 left-5 text-gray-500 "><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
                      </svg></span>
                    </div>
                  </div>

                  <div className="mt-6 w-64 sm:w-72 mx-auto py-6">
                    <button className="bg-slate-900 w-full mx-auto flex justify-center py-3 capitalize px-4 border text-lg xl font-semibold rounded-full text-white border-shine-400 hover:bg-slate-600 hover:text-white focus:outline-none focus:border-yellow-700 focus:shadow-outline-yellow active:bg-yellow-700 transition duration-150 ease-in-out" type="submit" onClick={loginSubmit}>{LoginJSON.signin}</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
