import React from 'react'
import { RxCross2 } from 'react-icons/rx'

function EditAppSetting({ isModalOpen, toggleModal, formData, handleSubmit, handleChange, setFormData }) {


    return isModalOpen && (
        <div
            id="default-modal"
            tabIndex={-1}
            aria-hidden="true"
            className="fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full h-full bg-black bg-opacity-50"
        >
            <div className="relative p-4 w-full max-w-2xl max-h-full">
                {/* Modal content */}
                <div className="relative bg-white rounded-lg shadow p-10">
                    {/* Modal header */}
                    <div className='flex justify-between mb-4'>
                        <div className='text-2xl font-bold'>
                            Edit App Setting
                        </div>
                        <div onClick={() => {
                            toggleModal()
                            setFormData({
                                id: '',
                                key: '',
                                value: '',
                                name: '',
                            })
                        }} className='flex items-center'>
                            <RxCross2 size={20} /> {" "}
                            Close
                        </div>
                    </div>
                    <form className="max-w-sm mx-auto" onSubmit={handleSubmit}>
                        <div className="mb-5">
                            <label
                                htmlFor="name"
                                className="block mb-2 text-sm font-medium text-gray-900"
                            >
                                Name
                            </label>
                            <input
                                type="text"
                                id="name"
                                value={formData.name}
                                onChange={handleChange}
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:border-gray-600 dark:placeholder-gray-400"
                                placeholder="Enter name"
                                required=""
                            />
                        </div>
                        <div className="mb-5">
                            <label
                                htmlFor="key"
                                className="block mb-2 text-sm font-medium text-gray-900"
                            >
                                Key
                            </label>
                            <input
                                type="text"
                                id="key"
                                disabled
                                value={formData.key}
                                onChange={handleChange}
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:border-gray-600 dark:placeholder-gray-400"
                                required=""
                            />
                        </div>

                        <div className="mb-5">
                            <label
                                htmlFor="value"
                                className="block mb-2 text-sm font-medium text-gray-900"
                            >
                                Value
                            </label>
                            <textarea
                                id="value"
                                rows={4}
                                value={formData.value}
                                onChange={handleChange}
                                className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500  dark:border-gray-600  "
                                placeholder="Enter value"
                                required=""
                            />
                        </div>
                        <button
                            type="submit"
                            className="text-white pt-3 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center "
                        >
                            Submit
                        </button>
                    </form>

                </div>
            </div>
        </div>
    )
}

export default EditAppSetting
