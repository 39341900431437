import React from "react";
import tableJSON from './table.json'



export default function TableMobileComponent() {
    return (
        <>
            <table className="leading-normal min-w-full">
                {tableJSON.tableRowData.map((ele, index1) => (
                    <tr className="flex flex-col min-w-full" key={index1}>
                        <td key={index1} className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                            <b>SR No.</b>:{" "}
                            <span className="relative">{index1 + 1}</span>
                        </td>
                        {Object.keys(tableJSON.tableHeading).map((key, index) => (
                            <td key={index} className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                                <b>{tableJSON.tableHeading[key]}</b>:{" "}
                                <span className="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
                                    <span
                                        className="absolute inset-0 bg-green-200 opacity-50 rounded-full"
                                    />
                                    <span className="relative">{ele[key]}</span>
                                </span>
                            </td>
                        ))}
                    </tr>
                ))}
            </table>
        </>

    );
}
