import React from "react";
import { Route, Routes } from 'react-router-dom';
import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";
import Dashboard from "../../pages/dashboard/Dashboard";
import AppSettings from "../../pages/AppSettings/index";
import layoutJSON from './layout.json'

function Layout() {
  return (
    <div>
      <div className="overflow-hidden bg-gray-100">
        <div className="min-h-screen flex flex-col justify-center">
          <div className="h-screen flex overflow-hidden">
            <Sidebar SidebarJSON = {layoutJSON.sidebarJSON}/>
            <div className="flex flex-col w-0 flex-1 overflow-hidden">
              <Header HeaderJSON={layoutJSON.headerJSON}/>
              <Routes>
                <Route path="/dashboard"  element={<Dashboard/>} />
                <Route path="/app-settings"  element={<AppSettings/>} />
              </Routes>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Layout;
