import { appSettingConstants } from '../_constants';

export default function appSetting(state = {}, action) {
  switch (action.type) {
    case appSettingConstants.CREATE_APP_SETTINGS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case appSettingConstants.CREATE_APP_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        createAppSettingSuccess: action.user

      };
    case appSettingConstants.CREATE_APP_SETTINGS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case appSettingConstants.GET_APP_SETTINGS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case appSettingConstants.GET_APP_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        getAppSettingSuccess: action.user.data

      };
    case appSettingConstants.GET_APP_SETTINGS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case appSettingConstants.UPDATE_APP_SETTINGS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case appSettingConstants.UPDATE_APP_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        updateAppSettingSuccess: action.user

      };
    case appSettingConstants.UPDATE_APP_SETTINGS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case appSettingConstants.DELETE_APP_SETTINGS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case appSettingConstants.DELETE_APP_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        deleteAppSettingSuccess: action.user

      };
    case appSettingConstants.DELETE_APP_SETTINGS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case appSettingConstants.UPDATE_STATUS_APP_SETTINGS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case appSettingConstants.UPDATE_STATUS_APP_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        updateStatusAppSettingSuccess: action.user

      };
    case appSettingConstants.UPDATE_STATUS_APP_SETTINGS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    default:
      return state
  }
}